@import 'vendors/font-awesome.scss';
@import 'vendors/icofont.scss';
@import "vendors/themify/_themify-icons.scss";
@import "vendors/flag-icon.scss";
@import "vendors/feather-icon/feather-icon";
@import "vendors/whether-icon.scss";
@import 'vendors/scrollbar.scss';
@import 'vendors/animate.scss';
@import 'vendors/prism.scss';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import "style";
@import "responsive";
@import "color-1.scss";
@import "vendors/dropzone.scss";
@import "./vendors/image-cropper.scss";
// @import "vendors/owlcarousel.scss";
// @import "vendors/icofont.scss";
@import "vendors/chartist.scss";


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu li a.active::before {
    background-color: white !important;
    opacity: 1;
    visibility: visible;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu::before {

    color: white !important;
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu::before {
    background: white !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu li a.active {

    color: white !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu li a {

    color: black !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
    vertical-align: middle;
    color: white !important;
    font-weight: 500;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-link.active .according-menu i {

    color: #4E0189 !important;

}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-link.active span {

    color: #4E0189 !important;
    transition: all 0.3s ease;
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li.sidebar-list:hover>a:hover {
    background-color: #fff;
}

.custom-select {
    width: 100%;
    padding: 7px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.link {
    color: #4E0189 !important;
}

.btn-primary {
    background-color: #4e0189 !important;
    border-color: #4e0189 !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a:hover svg {
    stroke: #4e0189 !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-link.active {
    background-color: #fff !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-submenu li a {
    color: #fff !important;
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li:hover .sidebar-link:not(.active):hover span {
    color: #4e0189 !important;
}

.custom-select[disabled] {
    background-color: #f4f4f4;
    cursor: not-allowed;
    color: black;
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
    top: unset !important;
}

.page-wrapper.compact-wrapper .page-header {
    margin-left: 0px !important;
    width: 100% !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
    padding: 0px !important;
    box-shadow: 0 !important;
}


.progress-bar {
    width: 100%;
    background-color: #b0b0b0;
    height: 20px;
    margin: 10px 0;
    border-radius: 14px;
  }
  
  .progress {
    background-color: #4caf50;
    height: 100% !important;
    width: 0;
    transition: width 0.3s ease;
    text-align: center;
    color: white;
    font-weight: bold;
  }